import { storeToRefs } from 'pinia';

export function useNewsletter() {
  const generalStore = useGeneralStore();
  const { toggleSubscription } = useNewsletterApi();

  const { isNewsletterSubscribed } = storeToRefs(generalStore);

  watch(isNewsletterSubscribed, async (value: boolean) => {
    await toggleSubscription(value);
  });

  return isNewsletterSubscribed;
}
